import React from "react"
import { Link } from "gatsby"

import Privacy from "./icons/privacy"
import Github from "./icons/github"
import Issues from "./icons/issues"

const InternalLink = ({ to, text, Icon }) => {
  return (
    <Link className="footer-link" to={to}>
      <Icon />
      {text}
    </Link>
  )
}

const ExternalLink = ({ to, text, Icon }) => {
  return (
    <a className="footer-link" target="_blank" rel="noreferrer" href={to}>
      <Icon />
      <span>{text}</span>
    </a>
  )
}

const Footer = () => {
  return (
    <footer>
      <ExternalLink
        to="https://github.com/mingchia-andy-liu/chrome-extension-nba"
        text="Github"
        Icon={Github}
      />
      <ExternalLink
        to="https://github.com/mingchia-andy-liu/chrome-extension-nba/issues"
        text="Issues"
        Icon={Issues}
      />
      <InternalLink to="/privacy" text="Privacy" Icon={Privacy} />
    </footer>
  )
}

export default Footer
