import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import LogoIcon from "../images/logo.svg"

const Header = ({ siteTitle }) => (
  <header>
    <h1>
      <Link to="/" className="header-link">
        <LogoIcon
          style={{ width: "3rem", height: "3rem", marginRight: "1rem" }}
        />
        {siteTitle}
      </Link>
    </h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
